"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Sprite = void 0;
const Factory_1 = require("../Factory");
const Shape_1 = require("../Shape");
const Animation_1 = require("../Animation");
const Validators_1 = require("../Validators");
const Global_1 = require("../Global");
class Sprite extends Shape_1.Shape {
  constructor(config) {
    super(config);
    this._updated = true;
    this.anim = new Animation_1.Animation(() => {
      var updated = this._updated;
      this._updated = false;
      return updated;
    });
    this.on('animationChange.konva', function () {
      this.frameIndex(0);
    });
    this.on('frameIndexChange.konva', function () {
      this._updated = true;
    });
    this.on('frameRateChange.konva', function () {
      if (!this.anim.isRunning()) {
        return;
      }
      clearInterval(this.interval);
      this._setInterval();
    });
  }
  _sceneFunc(context) {
    var anim = this.animation(),
      index = this.frameIndex(),
      ix4 = index * 4,
      set = this.animations()[anim],
      offsets = this.frameOffsets(),
      x = set[ix4 + 0],
      y = set[ix4 + 1],
      width = set[ix4 + 2],
      height = set[ix4 + 3],
      image = this.image();
    if (this.hasFill() || this.hasStroke()) {
      context.beginPath();
      context.rect(0, 0, width, height);
      context.closePath();
      context.fillStrokeShape(this);
    }
    if (image) {
      if (offsets) {
        var offset = offsets[anim],
          ix2 = index * 2;
        context.drawImage(image, x, y, width, height, offset[ix2 + 0], offset[ix2 + 1], width, height);
      } else {
        context.drawImage(image, x, y, width, height, 0, 0, width, height);
      }
    }
  }
  _hitFunc(context) {
    var anim = this.animation(),
      index = this.frameIndex(),
      ix4 = index * 4,
      set = this.animations()[anim],
      offsets = this.frameOffsets(),
      width = set[ix4 + 2],
      height = set[ix4 + 3];
    context.beginPath();
    if (offsets) {
      var offset = offsets[anim];
      var ix2 = index * 2;
      context.rect(offset[ix2 + 0], offset[ix2 + 1], width, height);
    } else {
      context.rect(0, 0, width, height);
    }
    context.closePath();
    context.fillShape(this);
  }
  _useBufferCanvas() {
    return super._useBufferCanvas(true);
  }
  _setInterval() {
    var that = this;
    this.interval = setInterval(function () {
      that._updateIndex();
    }, 1000 / this.frameRate());
  }
  start() {
    if (this.isRunning()) {
      return;
    }
    var layer = this.getLayer();
    this.anim.setLayers(layer);
    this._setInterval();
    this.anim.start();
  }
  stop() {
    this.anim.stop();
    clearInterval(this.interval);
  }
  isRunning() {
    return this.anim.isRunning();
  }
  _updateIndex() {
    var index = this.frameIndex(),
      animation = this.animation(),
      animations = this.animations(),
      anim = animations[animation],
      len = anim.length / 4;
    if (index < len - 1) {
      this.frameIndex(index + 1);
    } else {
      this.frameIndex(0);
    }
  }
}
exports.Sprite = Sprite;
Sprite.prototype.className = 'Sprite';
(0, Global_1._registerNode)(Sprite);
Factory_1.Factory.addGetterSetter(Sprite, 'animation');
Factory_1.Factory.addGetterSetter(Sprite, 'animations');
Factory_1.Factory.addGetterSetter(Sprite, 'frameOffsets');
Factory_1.Factory.addGetterSetter(Sprite, 'image');
Factory_1.Factory.addGetterSetter(Sprite, 'frameIndex', 0, (0, Validators_1.getNumberValidator)());
Factory_1.Factory.addGetterSetter(Sprite, 'frameRate', 17, (0, Validators_1.getNumberValidator)());
Factory_1.Factory.backCompat(Sprite, {
  index: 'frameIndex',
  getIndex: 'getFrameIndex',
  setIndex: 'setFrameIndex'
});